import {
    Box,
    Button,
    Card,
    CircularProgress,
    Container,
    Icon,
    makeStyles,
    TextField,
    Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import useSEO from '../components/Shared/useSEO'
import MailIcon from '@material-ui/icons/Mail'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
    textField: {
        marginBottom: theme.spacing(2),
    },
    card: {
        margin: `
    ${theme.spacing(2)}px 
    0 
    ${theme.spacing(2)}px 
    0`,
        padding: `
    ${theme.spacing(2)}px 
    ${theme.spacing(4)}px 
    ${theme.spacing(2)}px 
    ${theme.spacing(4)}px`,
        // border: theme.spacing(1),
        // borderStyle: 'solid',
        // borderRadius: 0,
        // borderColor: theme.palette.primary.main,
    },
    title: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    button: {
        '&.Mui-disabled': {
            color: theme.palette.common.white,
        },
    },
    contact: {
        'word-break': 'break-word',
    },
    contactCard: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(2),
    },
}))

type SendingState = 'initial' | 'sending' | 'done'
const initialState = { name: '', email: '', body: '' }

export default function Contact() {
    useSEO({ title: 'Kontakt' })
    const [sendingState, setSendingState] = useState<SendingState>('initial')
    const [formState, setFormState] = useState<typeof initialState>(initialState)

    const classes = useStyles()

    const handleFormFieldChange = (
        ev: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        setFormState(prev => {
            return { ...prev, [ev.target.id]: ev.target.value }
        })
    }
    const handleSubmit = () => {
        setSendingState('sending')
        fetch(
            `https://script.google.com/macros/s/AKfycbzuXBNb4glGNFutknwKJxZoe6PLT1LxgCxaEpSR7QbA-SQrNw/exec?name=${formState.name}&mail=${formState.email}&text=${formState.body}`,
            {
                method: 'POST',
                body: JSON.stringify({ title: 'React POST Request Example' }),
            }
        )
            .then(_res => setSendingState('done'))
            .catch(err => console.log(err))
    }
    return (
        <Container maxWidth="md">
            <Typography variant="h1" className={classes.title}>
                Lust auf Volleyball bekommen ?
            </Typography>
            <a href="mailto:kontakt@vthaemmerz.de?subject=Ich habe Lust auf Volleyball&body=Hallo Haemmerz, ...">
                <Card className={clsx(classes.card, classes.contactCard)}>
                    <MailIcon fontSize="large" color="secondary" />
                    <Typography
                        variant="h3"
                        align="center"
                        color="secondary"
                        className={classes.contact}>
                        kontakt@vthaemmerz.de
                    </Typography>
                </Card>
            </a>
            <Divider>ODER</Divider>
            <Card className={classes.card}>
                {sendingState !== 'done' ? (
                    <>
                        <TextField
                            disabled={sendingState === 'sending'}
                            className={classes.textField}
                            fullWidth
                            required
                            id="name"
                            label="Dein Name"
                            placeholder="Max Mustermann"
                            onChange={handleFormFieldChange}
                        />
                        <TextField
                            disabled={sendingState === 'sending'}
                            className={classes.textField}
                            fullWidth
                            required
                            id="email"
                            label="Deine Email"
                            placeholder="max.mustermann@mail.de"
                            onChange={handleFormFieldChange}
                        />
                        <TextField
                            disabled={sendingState === 'sending'}
                            className={classes.textField}
                            fullWidth
                            multiline
                            rows={6}
                            id="body"
                            label="Deine Frage"
                            placeholder="Schreib uns was nettes :)"
                            onChange={handleFormFieldChange}
                        />
                    </>
                ) : (
                    <Typography variant="h4">
                        Wir haben deine Anfrage erhalten und werden uns schnellstmöglich bei dir
                        melden.
                    </Typography>
                )}

                {sendingState !== 'done' && (
                    <Box display="flex" justifyContent="center" mt={2}>
                        <Button
                            className={classes.button}
                            disabled={sendingState === 'sending'}
                            endIcon={
                                sendingState === 'sending' && (
                                    <CircularProgress size="1.5rem" color="inherit" />
                                )
                            }
                            onClick={() => handleSubmit()}>
                            {sendingState === 'initial' ? 'Absenden' : 'Wird versendet'}
                        </Button>
                    </Box>
                )}
            </Card>
        </Container>
    )
}

const useDividerStyles = makeStyles(theme => ({
    divider: {
        margin: theme.spacing(4, 0),
        'flex-shrink': '0',
        display: 'flex',
        'white-space': 'nowrap',
        'text-align': 'center',
        border: '0px',
        '&::before, &::after': {
            position: 'relative',
            width: '100%',
            'border-top': `2px solid ${theme.palette.primary.main}`,
            top: '50%',
            content: '""',
            transform: 'translateY(50%)',
        },
    },
    dividerWrapper: {
        display: 'inline-block',
        'padding-left': 'calc(8px * 1.2)',
        'padding-right': 'calc(8px * 1.2)',
    },
}))

const Divider = ({ children }: React.PropsWithChildren<{}>) => {
    const classes = useDividerStyles()
    return (
        <div className={classes.divider}>
            <Typography variant="h4" className={classes.dividerWrapper}>
                {children}
            </Typography>
        </div>
    )
}
